import * as React from "react"
import scrollTo from "./scrollTo"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className='masthead'>
    <div className='header-grid p20 ml20 mr20 m-m0'>
      <Link to="/"><div className='logo--main' /></Link>
      <Menu />
      <div className='flex align-top'>
        <p className='button mla m0 m-small white' role='presentation' onClick={() => scrollTo('#contact')}>Contact Us</p>
      </div>
    </div>
  </header>
)

const Menu = () => {
  return (
    <div className='menu flex mt10 medium m-hide'>
      <p role='presentation' onClick={() => scrollTo('#who')} className='m0 pointer'>Who We Are</p>
      <p role='presentation' onClick={() => scrollTo('#projects')} className='mr40 m0 pointer ml40'>Projects</p>
      <p role='presentation' onClick={() => scrollTo('#team')} className='m0 pointer'>Our Team</p>
    </div>
  )
}

export default Header
